import React from "react";

function Icon({status}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 -960 960 960"
      fill={status? "#ffff" :"#038819 "}
    >
      <path d="M534-470q-29 0-48.5-19.5T466-538q0-29 19.5-48.5T534-606q29 0 48.5 19.5T602-538q0 29-19.5 48.5T534-470zM296-340q-24.75 0-42.375-17.625T236-400v-276q0-24.75 17.625-42.375T296-736h476q24.75 0 42.375 17.625T832-676v276q0 24.75-17.625 42.375T772-340H296zm28-28h420q0-25 17.625-42.5T804-428v-220q-25 0-42.5-17.625T744-708H324q0 25-17.625 42.5T264-648v220q25 0 42.5 17.625T324-368zm390 136H188q-24.75 0-42.375-17.625T128-292v-326h28v326q0 12 10 22t22 10h526v28zM296-368h-32v-340h32q-13 0-22.5 9.5T264-676v276q0 13 9.5 22.5T296-368z"></path>
    </svg>
  );
}

export default Icon;