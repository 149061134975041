import React from "react";

function Icon({isSelected}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 -960 960 960"
      fill={isSelected ? "#ffff" : "#000"}
    >
      <path d="M466-146v-315q-54 0-103-20t-87-58q-38-38-57.5-87.599Q199-676.198 199-730v-28h28q52.686 0 102.027 20.59Q378.367-716.819 416-679q32 32 51.5 73.5T492-518q10-20 22.5-37.5T543-589q37.863-37.819 87.507-58.41Q680.15-668 734-668h28v28q0 53.882-20.622 103.555-20.623 49.673-58.5 87.559Q645-411 596.5-391.5T494-372v226h-28zm1-344q0-48-18.5-91.5T396-659q-34-34-77.5-52.5T227-730q0 48 18 92t52 78q34 34 78 52t92 18zm27 90q48 0 91.5-18t77.5-52q34-34 52.5-78t18.5-92q-48 0-92 18.5T564-569q-34 34-52 77.5T494-400zm0 0zm-27-90z"></path>
    </svg>
  );
}

export default Icon;